import {Component,Input,Output,EventEmitter} from '@angular/core'
import { trigger, state, style,stagger, animate,animateChild, transition, query, sequence,group, keyframes } from '@angular/animations';

@Component({
    selector: 'faqs-card',
    templateUrl: './faqs-card.component.html',
    styleUrls: ['./faqs-card.component.scss'],
    animations: [
        trigger(
        'faqsToggl',[
            state('common',style({
            transform: 'rotateY(0deg)'
            })),
            state('rotated',style({
            transform: 'rotateY(180deg)'
            })),
            transition('common => rotated',[
            group([
                animate('0.5s linear'),
                query('[data-back]',[
                style({
                    height: '{{cardHeight}}',
                }),
                animate('0.5s linear',style({
                    height: '{{cardHeight}}'
                })),
                animate('{{time}} linear',style({
                    height: '*'
                })),
                ],{optional: true})
            ])
            ]),
            transition('rotated => common',[
                query('[data-back]',[
                style({
                    height: '{{currHeight}}',
                }),
                animate('{{time}} linear',style({
                    height: '{{cardHeight}}'
                })),
                ],{optional: true}),
                animate('0.5s linear')
            ]),
            transition('rotated => void',[
            style({
                transform: 'rotateY(180deg)'
            }),
            animate('0.5s linear',style({
                transform: 'rotateY(180deg)'
            }))
            ]),
            transition('common => void',[
              style({
                transform: 'rotateY(0deg)'
            }),
            animate('0.5s linear',style({
                transform: 'rotateY(0deg)'
            }))
            ])
        ]
        ),
        trigger(
            'faqsCard',[
              transition(':enter',[
                // group([
                  query('@faqsToggl',[
                    animateChild()
                  ],{optional:true}),
                //   style({
                //     opacity: '0',
                //     transform: 'translateY(25%)'
                //   }),
                //   animate('0.4s ease-out',style({
                //     opacity: '1',
                //     transform: 'translateY(0%)'
                //   }))
                // ])
              ]),
              transition(':leave',[
              //   group([
                  query('@faqsToggl',[
                    animateChild()
                  ],{optional:true}),
              //     style({
              //       opacity: '1',
              //       transform: 'translateY(0%)'
              //     }),
              //     animate('0.4s ease-out',style({
              //       opacity: '0',
              //       transform: 'translateY(-25%)'
              //     }))
              //   ])
              ])
            ]
        )
    ]
})

export class FaqsCard {
    @Input()
    faqNumber:number

    @Input() 
    minFaqPopH:string
    @Input()
    minFaqCardH:string
    @Input() 
    animH:string
    @Input() 
    openedFaq:string
    @Input()
    minText:boolean = false
    @Input()
    type:string;

    @Output()
    togglCard = new EventEmitter()
    @Output()
    faqsAnimFinished = new EventEmitter()

    constructor(){}

    togglFaqs(number){
        this.togglCard.emit(number)
    }
    faqsAnimFinish(e){
        if(
            (e.fromState === 'rotated' && e.toState === 'common') ||
            (e.fromState === 'common' && e.toState === 'rotated') 
          )
          {
              this.faqsAnimFinished.emit()
          }
    }
}