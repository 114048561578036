import { Component, Renderer2, OnInit, OnDestroy, afterNextRender } from "@angular/core";

@Component({
    selector: 'terms-dashbord',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit{
    constructor(private renderer: Renderer2){
        afterNextRender(() => {
            this.renderer.removeClass(document.body,'black-blocked')
        })
    }

    ngOnInit(): void {}
    ngOnDestroy():void {
        this.renderer.addClass(document.body,'black-blocked')
    }
}